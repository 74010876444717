import Menu from '@mui/icons-material/Menu';
import classNames from 'classnames';
import { useAppContext } from 'components/common/AppProvider';
import { HeaderLogo } from 'components/Layout/Header/HeaderLogo';
import { SgwtAccountCenter } from 'components/Layout/Header/SgwtAccountCenter';
import React, { useEffect, useRef, useState } from 'react';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { ResearchContractSelectors } from 'store/Normalizr/Selectors';

export const Header: React.FC = () => {
    const { dispatch, state: { appContext: { loggedUserId }, entities } } = useAppContext();
    const loggedUser = ResearchContractSelectors.getLoggedUser(loggedUserId, entities.researchContract);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getAppContext()).catch(() => void 0);
        const close = () => setDropdownOpen(false);
        const clickoutClose = (ev: MouseEvent) => {
            if (ev.target && !headerRef?.current?.contains(ev.target as Node)) {
                close();
            }
        };

        window.addEventListener('click', clickoutClose);
        return () => {
            window.removeEventListener('click', clickoutClose);
        };
    }, []);

    return <header className="sticky-top bg-lvl1" ref={headerRef}>
        <nav className="navbar">
            <div className="navbar-title">
                <HeaderLogo />
                {loggedUser && <>
                    <button className={classNames('d-lg-none navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center', { show: dropdownOpen })} onClick={toggle}>
                        <Menu className="icon" fontSize="large" />
                    </button>
                </>}
            </div>

            <div className="navbar-content">
                <div className="d-block flex-grow-1 mx-3"/>

                <SgwtAccountCenter />
            </div>
        </nav>
    </header>;
};
