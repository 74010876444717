import { useAppContext } from 'components/common/AppProvider';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { createSignOutAction } from 'store/AppContext/AppContextActions';
import { getAppContext } from 'store/AppContext/AppContextThunk';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-account-center': {
                    id?: string;
                    'available-languages'?: string;
                    'user-dropdown-links'?: any;
                    authentication?: string;
                    environment?: string;
                    language?: string;
                    mode?: 'sg-markets';
                    debug?: string;
                    ref?: (elt: HTMLElement) => void;
                    'show-sign-in-button'?: boolean;
                    'navigate-as'?: boolean;
                    'navigate-as-list'?: string;
                    'navigate-as-user'?: string;
                };
            }
        }
    }

    interface DocumentEventMap {
        [navigateAsLinkClickedEventName]: MouseEvent;
        [navigateAsEventName]: CustomEvent<INavigateAsEvent>;
        [stopNavigateAsEventName]: Event;
        [languageChangedEventName]: CustomEvent<ILanguageChangedEvent>;
        [signOutEventName]: MouseEvent;
    }
}

const navigateAsLinkClickedEventName = 'sgwt-account-center--navigate-as-link-clicked';
const navigateAsEventName = 'sgwt-account-center--navigate-as-select-user';
const stopNavigateAsEventName = 'sgwt-account-center--stop-navigation-as';
const languageChangedEventName = 'sgwt-account-center--language-changed';
const signOutEventName = 'sgwt-account-center--sign-out';

interface INavigateAsEvent {
    user: {
        id: string,
        name: string,
        company: string,
    }
}

interface ILanguageChangedEvent {
    language: string,
}

export const SgwtAccountCenter: React.FC = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const {
        dispatch,
        state: {
            appContext: {
                environment,
            },
        },
    } = useAppContext();

    const handleLanguageChange = (event: CustomEvent<ILanguageChangedEvent>) => {
        i18n.changeLanguage(event.detail.language);
    };

    const handleSignOut = () => {
        dispatch(createSignOutAction());
        navigate(RoutePaths.Contracts.AllContracts.route);
    };

    useEffect(() => {
        dispatch(getAppContext()).catch(() => void 0);
        document.addEventListener(languageChangedEventName, handleLanguageChange);
        document.addEventListener(signOutEventName, handleSignOut);

        return () => {
            document.removeEventListener(languageChangedEventName, handleLanguageChange);
            document.removeEventListener(signOutEventName, handleSignOut);
        };
    }, []);

    return <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`}>
        <sgwt-account-center
            id="sgwtAccountCenter"
            language="en"
            available-languages="en"
            authentication="sg-connect-v2"
            mode="sg-markets"
            producer-code="research_contract"
            environment={environment?.toUpperCase() === 'PRODUCTION' ? undefined : environment || undefined}
        />
    </WidgetLazyLoader>;
};
